import React from 'react';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Scroll from '../components/Scroll';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
          <div id="menu">
            <ul>
              <li
                onClick={e => {
                  e.preventDefault();
                  onMenuToggle();
                }}
              >
                <AnchorLink to="/">Home</AnchorLink>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  onMenuToggle();
                }}
              >
                {/* <AnchorLink to="/#about">About</AnchorLink> */}
                <Scroll type="id" element="experience">
                  <a href="/#">Experience</a>
                </Scroll>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  onMenuToggle();
                  scrollTo('#tech-stack');
                }}
              >
                <AnchorLink to="/#tech-stack">Technical Skills</AnchorLink>
              </li>
              <li
                onClick={e => {
                  e.preventDefault();
                  onMenuToggle();
                  scrollTo('#portfolio');
                }}
              >
                <AnchorLink to="/#projects">Projects</AnchorLink>
              </li>
              {/* <li>
                <Link to="/Generic">Generic Page</Link>
              </li>
              <li>
                <Link to="/Elements">Elements</Link>
              </li> */}
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
